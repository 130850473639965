import axios from 'axios';
import dompurify from 'dompurify';

function searchResultsHTML(habitats) {
    return habitats.map(habitat => {
        return `
            <a href="/habitat/${habitat.slug}" class="search__result">
                <strong>${habitat.name}</strong>
            </a>
        `;
    }).join('')
}

function typeAhead(search) {
    if (!search) return;

    const searchInput = search.querySelector('input[name="search"]');
    const searchResults = search.querySelector('.search__results');

    searchInput.on('input', function() {
        // if there is no value, quit it
        if (!this.value) {
            searchResults.style.display = 'none';
            return // Stop
        }

        // Otherwise, show search results
        searchResults.style.display = 'block';
        searchResults.innerHTML = '';

        axios
            .get(`/api/search/?q=${this.value}`)
            .then(res => {
                if(res.data.length) {
                    searchResults.innerHTML = dompurify.sanitize(searchResultsHTML(res.data));
                    return;
                }
                // Tell that nothing came back
                searchResults.innerHTML = dompurify.sanitize(`<class="search__result">No results for ${this.value} found</div>`);
            })
            .catch(err => {
                console.log(err)
            });
    });
    // Handling keyboard inputs on the typeAhead api
    searchInput.on('keyup', (e) => {
        // If users are not pressing the up, down, or enter key, no one cares
        if (![38, 40, 13].includes(e.keyCode)) {
            return; // sKIP IT
        }
        // Capturing the active higlighted habitat on the search dropdown
        const activeClass = 'search__result--active';
        const current = search.querySelector(`.${activeClass}`);
        const items = search.querySelectorAll('.search__result');
        let next;
        if(e.keyCode === 40 && current) {
            next = current.nextElementSibling || items[0];
        }else if(e.keyCode === 40) {
            next = items[0];
        }else if (e.keyCode === 38 && current) {
            next = current.previousElementSibling || [items.length - 1]
        }else if (e.keyCode === 38) {
            next = items[items.length - 1];
        }else if (e.keyCode === 13 && current.href) {
            window.location = current.href;
            return;
        }
        if (current) {
            current.classList.remove(activeClass)
        }
        next.classList.add(activeClass);
    });
}


export default typeAhead;