import '../sass/style.scss';

import { $, $$ } from './modules/bling';
import autocomplete from './modules/autocomplete';
import typeAhead from './modules/typeAhead';
import fr_typeAhead from './modules/fr_typeAhead';
import makeMap from './modules/map';
import fr_makeMap from './modules/fr_map';
import ajaxHeart from './modules/heart';

autocomplete( $('#address'), $('#lat'), $('#lng') );

typeAhead( $('.search') );
fr_typeAhead($('.fr_search'));
makeMap( $('#map') );
fr_makeMap($('#fr_map'));
const heartForms = $$('form.heart')
heartForms.on('submit', ajaxHeart)