import axios from 'axios';
import { $ } from './bling';

const mapOptions = {
    center: { lat: 15.2, lng: -4.37 },
    zoom: 6
}


// ================= FRENCH API ==========================

function fr_loadPlaces(map, lat = 15.2, lng = -4.37) {
    axios.get(`/fr_api/fr_habitats/near?lat=${lat}&lng=${lng}`)
        .then(res => {
            const places = res.data;
            if (!places.length) {
                alert('No places found!');
                return;
            }

            // Create bounds to zoom in on the habitats location markers queried
            const bounds = new google.maps.LatLngBounds();
            // Creating the info-window
            const infoWindow = new google.maps.InfoWindow();

            const markers = places.map(place => {
                const [placeLng, placeLat] = place.location.coordinates;
                const position = { lat: placeLat, lng: placeLng };
                bounds.extend(position);
                const marker = new google.maps.Marker({ map, position });
                marker.place = place;
                return marker;
            });

            // Show details of the place when one clicks on a marker
            markers.forEach(marker => marker.addListener('click', function () {
                console.log(this.place);
                const html = `
                <div class="popup">
                    <a href="/habitatFr/${this.place.slug}">
                        <img src="/uploads/${this.place.photo || 'beauty.png'}" alt="${this.place.name}"/>
                        <p>${this.place.name} - ${this.place.location.address}</p>
                    </a>
                </div>
            `;
                // Set the content of the info-window (can also display the photo)
                infoWindow.setContent(html);
                // Tell it where to open - of course should do so on the map
                infoWindow.open(map, this)
            }));

            // Zoom map to fit all the markers perfectly
            map.setCenter(bounds.getCenter());
            map.fitBounds(bounds);
        });
}

// ======================== END FRENCH VERSION =================================

function fr_makeMap(mapDiv) {
    if (!mapDiv) return;
    // Make the map
    const map = new google.maps.Map(mapDiv, mapOptions);
    fr_loadPlaces(map);

    const input = $('[name="fr_geolocate"]');
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        fr_loadPlaces(map, place.geometry.location.lng(), place.geometry.location.lat());
    })
}


export default fr_makeMap;